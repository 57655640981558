import React from 'react';
import { startRumTracing } from '@sm/otel-web';

export type RumSettings = {
  app: string;
  beaconUrl: string;
  environment: string;
  rumAuth: string;
  version?: string;
  sampleRate: number;
};

export type RumConfig = {
  enabled: boolean;
  settings: RumSettings;
};

const useRum = (rumConfig: RumConfig): void => {
  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const enabled = rumConfig?.enabled || false;
      const settings = rumConfig?.settings || {};
      const { app, beaconUrl, environment, rumAuth, version, sampleRate, ...options } = settings;

      startRumTracing({
        enabled,
        appName: app,
        beaconUrl,
        env: environment,
        rumAuth,
        sampleRate,
        version: version ?? '',
        options,
      });
    }
  }, [rumConfig]);
};

export default useRum;
