import React, { ReactElement } from 'react';
import { NextPage } from 'next';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import { ThemeProvider } from 'react-jss';
import { ApolloProvider, StoreObject } from '@apollo/client';
import { WrenchTheme as theme, GlobalStyles } from '@wds/styles';
import { L10nProviderProps } from '@sm/intl';
import { useApollo } from '~helpers/apolloClient';
import { LayoutType } from '~components/pageLayout/types';
import { StaticData } from '~helpers/pages/types';
import useRum from '~components/hooks/useRum';

type PageProps = {
  [dataId: string]: StoreObject | undefined;
  layout: LayoutType;
  translationData: L10nProviderProps;
  staticData: StaticData;
};

type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactElement;
};

type AppPropsWithLayout<P> = AppProps & {
  Component: NextPageWithLayout<P>;
};

const RespWeb = ({ Component, pageProps }: AppPropsWithLayout<PageProps>): JSX.Element => {
  const apolloClient = useApollo(pageProps);
  const getLayout = Component.getLayout ?? (page => page);

  useRum(pageProps.rum);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ApolloProvider client={apolloClient}>{getLayout(<Component {...pageProps} />)}</ApolloProvider>
    </ThemeProvider>
  );
};

export default RespWeb;

/**
 * Get and send web vital metrics to SignalFX RUM
 * ref: https://nextjs.org/docs/advanced-features/measuring-performance
 *
 * @param metrics - web vital metrics provided by Next.js
 *
 */
export const reportWebVitals = async (metrics: NextWebVitalsMetric): Promise<void> => {
  const { name, value: durationInMs, startTime: relativeStartTime } = metrics;

  const endTime = Date.now();
  const startTime = endTime - durationInMs;

  const { trace } = await import('@opentelemetry/api');

  trace
    .getTracer('appModuleLoader')
    .startSpan('Web Vital', { startTime })
    .setAttributes({
      'workflow.name': name,
      'webVital.name': name,
      'webVital.durationInMs': durationInMs,
      'webVital.relativeStartTime': relativeStartTime,
    })
    .end(endTime);
};
